<template lang="pug">
.d-flex.flex-column.align-items-center.justify-content-between
  .brand-mobile-onboarding-header
    om-logo-svg.mt-3.mb-3(width="138" height="27")
  transition(name="fade" mode="out-in")
    router-view
</template>

<script>
  import mobileOnboarding from '@/mixins/mobileOnboarding';

  export default {
    name: 'MobileOnboardingStepper',
    mixins: [mobileOnboarding],
  };
</script>
